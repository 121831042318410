<template>
    <div id="clients">

        <page-title icon="fas fa-trash" heading="Corbeille"  :options="[
            {title:'New client',id:'',class:'btn-success',icon:'fas fa-plus',event:()=>{onClientSelect(0);dialogs.client=true}},
            {title:'Upload',id:'upload',class:'btn-primary',icon:'fas fa-upload',event:()=>{dialogs.upload = !dialogs.upload}},

            {class:'btn-dark',icon:'fas fa-sync-alt',event:()=>{refresh({})}},
        ]">

        </page-title>
        <md-card>
            <div class="card-header" style="overflow: hidden">
                <span class="card-title m-0">{{msg("Basket")}}</span>


            </div>
            <!--Card Content-->
            <md-card-content>
                <!--search bar with accordion-->
                <div class="search-bar">
                    <div class="search-bar-input">
                        <b-input-group>
                            <input type="text" v-model="filter.search" :placeholder="msg('Search')"
                                   class="form-control">
                            <b-input-group-append v-b-toggle.collapse1 class="search-toggle btn btn-primary">
                                <div>
                                    <i class="fa fa-chevron-down"></i>
                                    <span> {{msg("OPEN FILTER").toUpperCase()}}</span>
                                </div>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    <div class="search-bar-input">
                        <b-collapse id="collapse1">
                            <form @submit.prevent="filterUpdate" class="row">
                                <div class="col-12 row ml-1 mr-1">
                                    <!--Id-->
                                    <div v-if="!isEnergyOrg" class="col-2">
                                        <md-field>
                                            <label>{{msg("ID")}}</label>
                                            <md-input v-model="filter.id"/>
                                        </md-field>
                                    </div>
                                    <!--External ID-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("External ID")}}</label>
                                            <md-input v-model="filter.externalid"/>
                                        </md-field>
                                    </div>
                                    <!--Creation Time-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Creation Time")}}</label>
                                            <VueCtkDateTimePicker :button-now-translation="msg('Now')" range no-shortcuts
                                                                  v-model="creationDatePicker"
                                                                  :locale="currentFormattedLang" :only-date="true"
                                                                  format="YYYY-MM-DD" formatted="ll" no-header
                                            >
                                                <md-input v-bind:value="creationDatePickerStr"/>
                                            </VueCtkDateTimePicker>
                                            <md-button class="md-icon-button md-xmini md-theme-default" @click="creationDatePicker = null" style="margin-top: 6px">
                                                <md-icon><i class="fas fa-times"></i></md-icon>
                                            </md-button>
                                        </md-field>
                                    </div>

                                    <!--Assignation Time-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Assignation Time")}}</label>
                                            <VueCtkDateTimePicker :button-now-translation="msg('Now')" range no-shortcuts
                                                                  v-model="assignationDatePicker"
                                                                  :locale="currentFormattedLang" :only-date="true"
                                                                  format="YYYY-MM-DD" formatted="ll" no-header
                                            >
                                                <md-input v-bind:value="assignationDatePickerStr"/>
                                            </VueCtkDateTimePicker>
                                            <md-button class="md-icon-button md-xmini md-theme-default" @click="assignationDatePicker = null" style="margin-top: 6px">
                                                <md-icon><i class="fas fa-times"></i></md-icon>
                                            </md-button>
                                        </md-field>
                                    </div>

                                    <!--First name-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("First name")}}</label>
                                            <md-input v-model="filter.firstname"/>
                                        </md-field>
                                    </div>
                                    <!--Last name-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Last name")}}</label>
                                            <md-input v-model="filter.lastname"/>
                                        </md-field>
                                    </div>
                                    <!--Language-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Language")}}</label>
                                            <md-select v-model="filter.lang">
                                                <md-option value=""></md-option>
                                                <md-option v-for="lan in getAllLanguage" v-bind:value="lan.id"
                                                           v-bind:key="lan.id">
                                                    {{ lan.language }}
                                                </md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <!--Country-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Country")}}</label>
                                            <md-select v-model="filter.country">
                                                <md-option value=""></md-option>
                                                <md-option v-for="c in getAllCountries" v-bind:value="c.iso"
                                                           v-bind:key="c.id">
                                                    {{ c.name }}
                                                </md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <!--Phone-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Phone")}}</label>
                                            <md-input v-model="filter.phone">
                                            </md-input>
                                        </md-field>
                                    </div>
                                    <!--Email-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Email")}}</label>
                                            <md-input v-model="filter.email"/>
                                        </md-field>
                                    </div>
                                    <!--Representative-->
                                    <div v-if="canEditRep" class="col-2">

                                        <v-select class="mt-2"
                                                  v-model="filter.representativeidList"
                                                  :items="addNotAssign(getUsersNotByRole(roleInstalIs))"
                                                  :item-text="(us)=>(us.firstname + ' ' + us.lastname)"
                                                  item-value="id"
                                                  :menu-props="{ maxHeight: '400' }"
                                                  :label="msg('Representative')"
                                                  multiple
                                                  persistent-hint
                                        ></v-select>
                                    </div>

                                    <!--Representative2-->
                                    <div v-if="isEnergyOrg&& canEditRep&&roleInstalIs>0" class="col-2">

                                        <v-select class="mt-2"
                                                  v-model="filter.representativeidList2"
                                                  :items="addNotAssign(getUsersByRole(roleInstalIs))"
                                                  :item-text="(us)=>(us.firstname + ' ' + us.lastname)"
                                                  item-value="id"
                                                  :menu-props="{ maxHeight: '400' }"
                                                  :label="msg('Fitter')"
                                                  multiple
                                                  persistent-hint
                                        ></v-select>
                                    </div>


                                    <!--Representative3-->
                                    <div v-if="isEnergyOrg&& canEditRep&&roleCommIs>0" class="col-2">

                                        <v-select class="mt-2"
                                                  v-model="filter.representativeId3"
                                                  :items="addNotAssign(getUsersByRole(roleCommIs))"
                                                  :item-text="(us)=>(us.firstname + ' ' + us.lastname)"
                                                  item-value="id"
                                                  :menu-props="{ maxHeight: '400' }"
                                                  :label="rep3Alias"
                                                  multiple
                                                  persistent-hint
                                        ></v-select>
                                    </div>

                                    <!--Representative3-->
                                    <div v-if="isEnergyOrg&& canEditRep&&roleConfirmIs>0" class="col-2">

                                        <v-select class="mt-2"
                                                  v-model="filter.representativeId4"
                                                  :items="addNotAssign(getUsersByRole(roleConfirmIs))"
                                                  :item-text="(us)=>(us.firstname + ' ' + us.lastname)"
                                                  item-value="id"
                                                  :menu-props="{ maxHeight: '400' }"
                                                  :label="msg('Confirmator')"
                                                  multiple
                                                  persistent-hint
                                        ></v-select>
                                    </div>

                                    <!--Zip-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Zip")}}</label>
                                            <md-input v-model="filter.zip"/>
                                        </md-field>
                                    </div>

                                    <!--Representative2-->
                                    <div class="col-2">

                                        <v-select class="mt-2"
                                                  v-model="filter.statusList"
                                                  :items="addNotAssign(getAllStatus)"
                                                  item-text="status"
                                                  item-value="id"
                                                  :menu-props="{ maxHeight: '400' }"
                                                  :label="msg('Status')"
                                                  multiple
                                                  persistent-hint
                                        ></v-select>
                                    </div>

                                    <!--Language-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Affiliate")}}</label>
                                            <md-select v-model="filter.affId">
                                                <md-option value=""></md-option>
                                                <md-option v-for="lan in getAllAffiliate" v-bind:value="lan.id"
                                                           v-bind:key="lan.id">
                                                    {{ lan.company }}
                                                </md-option>
                                            </md-select>
                                        </md-field>
                                    </div>

                                    <!--Language-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Campaign")}}</label>
                                            <md-select v-model="filter.camId">
                                                <md-option value=""></md-option>
                                                <md-option v-for="lan in getAllCampaigns" v-bind:value="lan.id"
                                                           v-bind:key="lan.id">
                                                    {{ lan.name }}
                                                </md-option>
                                            </md-select>
                                        </md-field>
                                    </div>

                                    <!--Phone-->
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Tag")}}</label>
                                            <md-input v-model="filter.tag">
                                            </md-input>
                                        </md-field>
                                    </div>
                                    <!--&lt;!&ndash;Statusid&ndash;&gt;
                                    <div class="col-2">
                                        <md-field>
                                            <label>{{msg("Status")}}</label>
                                            <md-select v-model="filter.statusid">
                                                <md-option value=""></md-option>
                                                <md-option v-for="st in getAllStatus" v-bind:value="st.id"
                                                           v-bind:key="st.id">
                                                    {{ st.status }}
                                                </md-option>
                                            </md-select>
                                        </md-field>
                                    </div>-->
                                </div>
                            </form>
                        </b-collapse>
                    </div>
                </div>
                <!--Table-->
                <client-table :filter="filter" @clientSelect="onClientSelect"
                              :columns="{company:isCompanyClients}"
                              client-operations :deleteStatus="1"
                />

                <!--Components-->
                <!--Edit client Modal -->
                <portal to="footer">
                    <client-Modal :id="selected" :dialog.sync="dialogs.client" @closeDialog="closeDialog" @newClient="onNewClient" @clientUpdated="onClientUpdate" can-create/>
                </portal>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import clientModal from "../../components/modals/clientModal";
    import clientTable from "../../components/components/clientTable";
     import PageTitle from "@/@core/components/page-title/PageTitle";


    export default {
        name: "clients",
        components: {PageTitle, clientModal, clientTable},
        data() {
            return {
                filter: {
                    creationDateFrom: null,
                    creationDateUtil: null,
                    assignationDateFrom: null,
                    assignationDateUtil: null,
                    active:0
                },

                //Modal
                selected: null,


                //Dialogs to show
                dialogs: {client: false, upload: false},

                file: {},
                popups: {addGroup: false, tag: false}

            }
        },

        computed: {
            ...mapGetters('translator', ['msg', 'currentFormattedLang']),
            ...mapGetters('data', ['getAllCampaigns', 'getAllAffiliate', 'getOtherUsers', 'getUsersByRole', 'getUsersNotByRole', 'getUserFullName', 'getUserAcronyms', 'getAllLanguage', 'getAllCountries', 'getAllUsers', 'getAllBranches', 'isEnergyOrg', 'isCompanyClients']),
            ...mapGetters('clients', ['rep3Alias','getStatus', 'getAllStatus', 'roleInstalIs', 'roleCommIs', 'roleConfirmIs', 'canEditRep']),

            tags() {
                return {
                    client: ['firstname', 'lastname', 'email', 'username', 'password', 'external'],
                    organization: ['org_name', 'org_phone'],
                    order: ['order_total'],
                    calendar: ['appointment'],
                    login: ['token'],
                    form:['f(formulaire id)_p(position question)'],
                };
            },
            creationDatePicker: {
                get() {
                    return {start: this.filter.creationDateFrom, end: this.filter.creationDateUtil}
                },
                set(v) {
                    if (v == null)
                        v = {};
                    this.filter.creationDateFrom = v.start;
                    this.filter.creationDateUtil = v.end
                }
            },
            creationDatePickerStr() {
                if (this.creationDatePicker.start == null)
                    return "";
                let str = this.$moment(this.creationDatePicker.start).format('DD/MM/YY');
                if (this.creationDatePicker.end == null)
                    return str;
                return str + " - " + this.$moment(this.creationDatePicker.end).format('DD/MM/YY')
            },
            assignationDatePicker: {
                get() {
                    return {start: this.filter.assignationDateFrom, end: this.filter.assignationDateUtil}
                },
                set(v) {
                    if (v == null)
                        v = {};
                    this.filter.assignationDateFrom = v.start;
                    this.filter.assignationDateUtil = v.end
                }
            },
            assignationDatePickerStr() {
                if (this.assignationDatePicker.start == null)
                    return "";

                let str = this.$moment(this.assignationDatePicker.start).format('DD/MM/YY');
                if (this.assignationDatePicker.end == null)
                    return str;

                return str + " - " + this.$moment(this.assignationDatePicker.end).format('DD/MM/YY')
            }
        },

        methods: {
            ...mapActions('clients', {
                _uploadFile: "uploadClientsFile",
            }),
            addNotAssign(arr) {
                let $this = this;
                if (typeof arr === "object")
                    arr = Object.values(arr);
                return [{
                    id: -1,
                    status: $this.msg('Not assign'),
                    firstname: $this.msg('Not assign'),
                    lastname: ''
                }, ...arr]
            },
            refresh() {
                console.log("clients->refresh()");
                this.$root.$emit('crm::client-table::refresh');
            },
            dismissPopover() {
                this.$root.$emit('bv::hide::popover');
                Object.keys(this.operations).forEach((k) => this.operations[k] = false);
            },
            onClientSelect(client) {
                this.selected = client.id;
                this.dialogs.client = true;
                this.$root.$emit('bv::hide::popover');
            },
            onNewClient(clientId) {
                console.log("Clients -> onNewClient()", clientId)
                clientId = parseInt(clientId);
                if (!isNaN(clientId) && clientId > 0) {
                    this.selected = clientId;
                    this.refresh()
                }
            },
            onClientUpdate(status) {
                if (status === true) {
                    this.refresh()
                }
            },
            filterUpdate() {
                this.selectedRows = []
                this.refresh()

            },
            closeDialog() {
                let $this = this;
                $this.dialogs.client = false;
                setTimeout(() => $this.selected = null, 500);
            },
            uploadFile() {
                let $this = this;
                this._uploadFile(this.file).finally(() => $this.refresh());

            }
        }
    }
</script>

<style>

</style>
